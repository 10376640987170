import { render, staticRenderFns } from "./vipPay.vue?vue&type=template&id=c5495932&scoped=true&"
import script from "./vipPay.vue?vue&type=script&lang=js&"
export * from "./vipPay.vue?vue&type=script&lang=js&"
import style0 from "./vipPay.vue?vue&type=style&index=0&id=c5495932&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5495932",
  null
  
)

export default component.exports