<!--
 * @Description: 会员支付购买
 * @Author: huacong
 * @Date: 2021-06-08 10:20:38
 * @LastEditTime: 2021-09-01 15:11:06
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <section>
      <div class="bgfff">
        <el-row class="infoTop">
          <el-col :span="8">
            客服工作时间：9:00—18:00
          </el-col>
          <el-col :span="8" class="text-center">
            服务热线：021-50189226
          </el-col>
          <el-col :span="8" class="text-right">
            在线QQ：2413614556
          </el-col>
        </el-row>
        <p class="viptext">
          选择会员：
        </p>
        <!-- 会员类型 -->
        <el-row :gutter="60" style="padding:0px 80px;margin: 0">
          <el-col :span="12" class="vipTypeView">
            <div class="vipTypeBox" @click="changePayType(1, 9.9, $event)">
              <div>
                <div class="infoLeft">
                  <p>7天体验卡 <span style="font-size: 14px"></span></p>
                  <p>￥<span>9.90</span></p>
                  <p>￥<span>199.90</span></p>
                  <p>立省<span>190</span></p>
                </div>
              </div>
              <div>
                <div class="infoRight">
                  <p>信息发布：200条</p>
                  <p>刷新：50次</p>
                  <p>下载：50次</p>
                  <p>有效期：7天</p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="vipTypeBox" @click="changePayType(2, 499.0, $event)">
              <div>
                <div class="infoLeft">
                  <p>月会员</p>
                  <p>￥<span>499.00</span></p>
                  <p>￥<span>799.00</span></p>
                  <p>立省<span>299</span></p>
                </div>
              </div>
              <div>
                <div class="infoRight">
                  <p>信息发布：600条</p>
                  <p>刷新：180次</p>
                  <p>下载：180次</p>
                  <p>有效期：30天</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="60" style="padding:0px 80px;margin: 0">
          <el-col :span="12" class="vipTypeView">
            <div class="vipTypeBox" @click="changePayType(3, 1299.0, $event)">
              <div>
                <div class="infoLeft">
                  <p>季会员</p>
                  <p>￥<span>1299.00</span></p>
                  <p>￥<span>2299.00</span></p>
                  <p>立省<span>1000</span></p>
                </div>
              </div>
              <div>
                <div class="infoRight">
                  <p>信息发布：1800条</p>
                  <p>刷新：600次</p>
                  <p>下载：600次</p>
                  <p>有效期：90天</p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="vipTypeBox" @click="changePayType(4, 2499.0, $event)">
              <div>
                <div class="infoLeft">
                  <p>年会员</p>
                  <p>￥<span>2499.00</span></p>
                  <p>￥<span>7999.00</span></p>
                  <p>立省<span>5500</span></p>
                </div>
              </div>
              <div>
                <div class="infoRight">
                  <p>信息发布：10000条</p>
                  <p>刷新：3200次</p>
                  <p>下载：3200次</p>
                  <p>有效期：365天</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 说明 -->
        <el-row :gutter="60" style="padding:0px 80px;margin: 0">
          <el-col :span="24" class="stateInfo" v-if="warnTitle">
            <span>I</span>
            <b class="font-red">*</b>
            您所选择的7天体验卡，每个用户只可购买一次。如果您已购买过，请选择其他类型会员。
          </el-col>
          <el-col :span="24" class="stateInfo">
            <span>I</span>
            VIP套餐发放的未使用完的简历券
            （包含分配出的简历券）到期（自购买日起，每30天为一个周期）回收。
          </el-col>
          <el-col :span="24" class="stateInfo">
            <span>I</span>
            VIP每日下载简历上限200份；非VIP不能下载。
          </el-col>
          <!-- <el-col :span="24" class="stateInfo">
            <span>I</span>
            温馨提示：下载简历根据不同专业扣除下载次数！
          </el-col> -->
        </el-row>
        <!-- 付款 -->
        <div class="payBox text-center">
          <p>
            订单金额：
            <span
              >{{ payNum }}
              <span v-if="payNum != 9.9">
                .00
              </span>
            </span>
            元
          </p>
          <el-button
            type="primary"
            class="radius-25"
            style="width:200px"
            v-prevent-repeat-click
            @click="payPost"
            >立即购买</el-button
          >
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      payNum: 0,
      partType: "",
      warnTitle: false,
    };
  },
  methods: {
    ...mapMutations([
      "setorderId",
      "setorderTime",
      "setorderType",
      "setorderFee",
      "setcodeImg",
    ]),
    changePayType(type, num, event) {
      if (type == 1) {
        this.warnTitle = true;
      } else {
        this.warnTitle = false;
      }
      this.partType = type;
      this.payNum = num;
      $(".vipTypeBox").removeClass("fontActive");
      var el = event.currentTarget;
      el.classList.add("fontActive");
    },
    payPost() {
      if (!this.partType) {
        this.$message.warning("请选择购买的会员！");
        return;
      }
      switch (this.partType) {
        case 1:
          this.setorderType({
            orderType: "周会员",
          });
          break;
        case 2:
          this.setorderType({
            orderType: "月会员",
          });
          break;
        case 3:
          this.setorderType({
            orderType: "季会员",
          });
          break;
        case 4:
          this.setorderType({
            orderType: "年会员",
          });
          break;
      }
      this.setorderFee({
        orderFee: this.payNum,
      });
      this.$http
        .post("index/recharge/codepay", {
          type: this.partType,
          payAmount: this.payNum,
        })
        .then((res) => {
          if (res.data.resultCode === 1) {
            let date = this.getNowTime();
            this.setorderTime({
              orderTime: date,
            });
            this.setorderId({
              orderId: res.data.resultData.outTradeNo,
            });
            this.setcodeImg({
              codeImg: res.data.resultData.url,
            });
            this.$router.push("weChatPay");
          }
        });
    },
    getNowTime() {
      let nowDate = new Date();
      let y = nowDate.getFullYear();
      let m =
        nowDate.getMonth() + 1 > 9
          ? nowDate.getMonth() + 1
          : "0" + (nowDate.getMonth() + 1).toString();
      let d =
        nowDate.getDate() > 9 ? nowDate.getDate() : "0" + nowDate.getDate();
      let h =
        nowDate.getHours() > 9 ? nowDate.getHours() : "0" + nowDate.getHours();
      let f =
        nowDate.getMinutes() > 9
          ? nowDate.getMinutes()
          : "0" + nowDate.getMinutes();
      let s =
        nowDate.getSeconds() > 9
          ? nowDate.getSeconds()
          : "0" + nowDate.getSeconds();
      return y + "-" + m + "-" + d + " " + h + ":" + f + ":" + s;
    },
  },
};
</script>
<style scoped>
.infoTop {
  padding: 10px 20px;
  border-bottom: 1px solid #e8e8e8;
}
.vipTypeView {
  margin-bottom: 40px;
}
.viptext {
  padding: 20px;
  color: #000;
}
.vipTypeBox {
  height: 200px;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px 2px #e8e8e8;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 47% 53%;
  grid-template-rows: 100%;
}
.vipTypeBox:hover {
  box-shadow: 0px 0px 3px 2px rgba(255, 178, 0, 0.3);
  border: 2px solid #f7b500;
  background: url("../../../assets/icon/vip-active1.png") no-repeat;
  background-position: right bottom;
}
.vipTypeBox.fontActive {
  box-shadow: 0px 0px 3px 2px rgba(255, 178, 0, 0.3);
  border: 2px solid #f7b500;
  background: url("../../../assets/icon/vip-active1.png") no-repeat;
  background-position: right bottom;
}
.infoLeft {
  width: 220px;
  height: 200px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.infoRight {
  height: 200px;
  display: table-cell;
  vertical-align: middle;
}
.infoLeft p:first-child {
  font-size: 32px;
  line-height: 48px;
}
.infoLeft p:nth-child(2) {
  font-size: 28px;
  color: #fa6400;
  line-height: 45px;
}
.infoLeft p:nth-child(3) {
  font-size: 18px;
  color: #999999;
  line-height: 27px;
  text-decoration: line-through;
}
.infoLeft p:nth-child(4) {
  font-size: 18px;
  color: #fa6400;
  line-height: 45px;
}
.infoRight p {
  font-size: 18px;
  color: #999999;
  line-height: 27px;
}
.stateInfo {
  line-height: 24px;
  margin: 5px 0px;
}
.stateInfo span {
  font-weight: bold;
  color: #f7b500;
  margin-right: 5px;
}
.payBox {
  margin-top: 10px;
  padding-bottom: 40px;
}
.payBox p {
  font-size: 28px;
  margin-bottom: 20px;
}
.payBox p span {
  color: #fa6400;
}
</style>
